const dossierSlides = [
    {
        title: 'Dashboard',
        content: 'Dankzij jouw eigen persoonlijke dashboard houd je alles moeiteloos onder controle.',
        image: require('@/assets/dashboard/Dashboard.png'),
        left: 5,
        top: 64,
    },
    {
        title: 'Afspraken',
        content: 'Dankzij de agendategel heb je al je afspraken overzichtelijk bij de hand, en met slechts één klik open je direct het dossier van je cliënt.',
        image: require('@/assets/dashboard/Dashboard.png'),
        left: 70,
        top: 16,
    },
    {
        title: 'Proces monitoring',
        content: 'Dankzij de actieve procesmonitoring behoud je te allen tijde volledige controle.',
        image: require('@/assets/dashboard/Dashboard.png'),
        left: 50,
        top: 32,
    },
]

export default dossierSlides
<template>
  <div v-if="noraUitgelichtModalService.state.active" class="modal modal-lg show" id="noraUitgelichtModal" tabindex="-1" role="dialog" style="display:block; font-family: 'Nunito', sans-serif;">
    <div class="modal-dialog modal-xl modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body ps-0 pe-0 overflow-auto" style="min-height: 600px; max-height: 600px;">
          <div class="container ps-0 pe-0 overflow-x-hidden text-secondary" style="font-family: 'Nunito', system-ui; font-size: 16px;">
            <div class="row mb-3 sticky-top">
              <div class="col-2"/>
              <div class="col">
                <h5 class="card-title fw-bold" style="color: #4A90E2;">Nora uitgelicht</h5>
              </div>
              <div class="col-2">
                <button type="button" class="btn-close" @click="hideModal()"></button>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col"/>
              <div class="col-9">
                <div class="row mb-2">
                  <div class="col">
                    <h2
                        style="color: #12A0D7;"
                        v-html="noraUitgelicht[currentNoraUitgelicht].title"
                    />
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-2">
                    <div v-if="currentNoraUitgelicht > 0" class="d-inline-block me-2" @click="priorMovie()">
                      <img src="@/assets/back-32.png" ref="back" class="img-fluid"/>
                    </div>
                    <div v-else class="d-inline-block me-2" style="filter: grayscale(1);">
                      <img src="@/assets/back-32.png" ref="back" class="img-fluid"/>
                    </div>
                  </div>
                  <div class="col">
                    {{ noraUitgelicht[currentNoraUitgelicht].content }}
                  </div>
                  <div class="col-2">
                    <div v-if="currentNoraUitgelicht < noraUitgelicht.length - 1" class="d-inline-block ms-2" @click="nextMovie()">
                      <img src="@/assets/next-32.png" ref="back" class="img-fluid"/>
                    </div>
                    <div v-else class="d-inline-block ms-2" style="filter: grayscale(1);">
                      <img src="@/assets/next-32.png" ref="back" class="img-fluid"/>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col">
                    <div class="web-player">
                      <vue-plyr ref="player">
                        <div
                            class="web-player"
                            data-plyr-provider="youtube"
                            :data-plyr-embed-id="noraUitgelicht[currentNoraUitgelicht].youtube">
                        </div>
                      </vue-plyr>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col"/>
            </div>
            <div class="row mb-2">
              <div class="col"/>
              <div class="col-8">
                <NoraBubble :message="'Abonneer je op ons youtube kanaal om op de hoogte te blijven!'" :top="100"/>
              </div>
              <div class="col"/>
            </div>

          </div>
        </div>

        <div class="modal-footer bg-light pt-1 pb-1">
          <button type="button" class="btn btn-secondary opacity-75" @click="hideModal()">Sluiten</button>
        </div>
      </div>
    </div>
  </div>

  <div v-show="noraUitgelichtModalService.state.active" class="dialog-bg"></div>
</template>

<script>
import demoModalService from "@/services/modals/demoModalService";
import noraUitgelichtModalService from "@/services/modals/noraUitgelichtModalService";
import NoraBubble from "@/components/nora/NoraBubble";
import noraUitgelicht from "@/services/NoraUitgelicht";

export default {
  name: "NoraUitgelichtModal",
  components: {
    NoraBubble,
  },
  data() {
    return {
      player: {},
      currentNoraUitgelicht: 0,
      noraUitgelicht,
      noraUitgelichtModalService,
    };
  },
  methods: {
    hideModal() {
      noraUitgelichtModalService.cancel()
    },
    setPlayer() {
      this.$refs.player.player.source = {
        type: 'video',
        sources: [
          {
            src: this.noraUitgelicht[this.currentNoraUitgelicht].youtube,
            provider: 'youtube',
          }
        ]
      }
    },
    nextMovie() {
      this.currentNoraUitgelicht++
      this.setPlayer()
    },
    priorMovie() {
      this.currentNoraUitgelicht--
      this.setPlayer()
    },
    isMobile2() {
      return (screen.width <= 760)
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    showDemoModal() {
      noraUitgelichtModalService.cancel()
      demoModalService.show()
    },
  },
}
</script>

<style>
.vueperslides__arrow {
  color: #4A90E2;
}
.mirror {
  -moz-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.web-player {
  border-radius: 10px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
</style>
<template>
  <div class="shapedividers_com-1329">
      <div class="container">
        <div class="row mt-3">
          <h2
              style="color: #12A0D7;"
              v-html="'Waaruit bestaat Nora software?'"
          />
        </div>
        <div class="row mt-4">
          <!-- Agenda -->
          <div class="col-sm-4">
            <div class="card mb-3 shadow" @mouseover="selectedTile = 1" @mouseout="selectedTile = -1" style="min-height: 450px;">
              <div class="card-header p-1 opacity-75" :style="selectedTile === 1 ? 'background-color: #003C82;' : 'background-color: #12A0D7;'">
                <h5 style="margin-bottom: 2px; color: white;">
                  Agenda
                </h5>
              </div>
              <div class="card-body" :class="selectedTile === 1 ? 'schedule-tile' : 'general-tile'" @click="showInvoicingModal('schedule')">
                <div class="row mb-2">
                  <div class="col">
                    <img src="@/assets/schedule-48.png" alt="" class="img-fluid"/>
                  </div>
                </div>
                <div class="row mb-2 text-secondary">
                  <div class="col">
                    Stel je voor dat je met slechts enkele klikken afspraken kunt maken en beheren in een overzichtelijke agenda.
                    Geen gedoe meer met papieren agenda's of onhandige spreadsheets. Nora doet het werk voor jou, zodat je
                    je kunt concentreren op wat er echt toe doet: het behandelen van je cliënten en het verbeteren van hun welzijn.
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col">
                    <img src="@/assets/check-24.png" alt="logo" class="img-fluid" style="width: 24px;"/>
                    Overzichtelijke agenda
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col">
                    <img src="@/assets/check-24.png" alt="logo" class="img-fluid" style="width: 24px;"/>
                    Eenvoudig afspraken maken
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col">
                    <img src="@/assets/check-24.png" alt="logo" class="img-fluid" style="width: 24px;"/>
                    Afspraakbevestigingen
                  </div>
                </div>
                <div class="row mb-2 sticky-bottom-left">
                  <div class="col" @click="showInvoicingModal('schedule')">
                    <div class="read-more">
                      Lees meer
                      <img src="@/assets/next-20.png" alt="" class="img-fluid"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Factureren -->
          <div class="col-sm-4">
            <div class="card mb-3 shadow" @mouseover="selectedTile = 4" @mouseout="selectedTile = -1" style="min-height: 450px;">
              <div class="card-header p-1 opacity-75" :style="selectedTile === 4 ? 'background-color: #003C82;' : 'background-color: #12A0D7'">
                <h5 style="margin-bottom: 2px; color: white;">
                  Facturering
                </h5>
              </div>
              <div class="card-body" :class="selectedTile === 4 ? 'schedule-tile' : 'general-tile'" @click="showInvoicingModal('invoicing')">
                <div class="row mb-2">
                  <div class="col">
                    <img src="@/assets/coins-48.png" alt="" class="img-fluid"/>
                  </div>
                </div>
                <div class="row mb-2 text-secondary">
                  <div class="col">
                    Als het gaat om administratie, hoef je je geen zorgen meer te maken. Nora zorgt voor naadloze facturering,
                    zodat je snel en gemakkelijk betaald krijgt voor je diensten.
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col">
                    <img src="@/assets/check-24.png" alt="logo" class="img-fluid" style="width: 24px;"/>
                    Factureren in 1 klik
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col">
                    <img src="@/assets/check-24.png" alt="logo" class="img-fluid" style="width: 24px;"/>
                    Betalingsoverzicht
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col">
                    <img src="@/assets/check-24.png" alt="logo" class="img-fluid" style="width: 24px;"/>
                    Facturen mailen
                  </div>
                </div>
                <div class="row mb-2 sticky-bottom-left">
                  <div class="col" @click="showInvoicingModal('invoicing')">
                    <div class="read-more">
                      Lees meer
                      <img src="@/assets/next-20.png" alt="" class="img-fluid"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Dossier -->
          <div class="col-sm-4">
            <div class="card mb-3 shadow" @mouseover="selectedTile = 3" @mouseout="selectedTile = -1" style="min-height: 450px;">
              <div class="card-header p-1 opacity-75" :style="selectedTile === 3 ? 'background-color: #003C82;' : 'background-color: #12A0D7;'">
                <h5 style="margin-bottom: 2px; color: white;">
                  Dossier
                </h5>
              </div>
              <div class="card-body" :class="selectedTile === 3 ? 'schedule-tile' : 'general-tile'" @click="showInvoicingModal('dossier')">
                <div class="row mb-2">
                  <div class="col">
                    <img src="@/assets/book-48.png" alt="" class="img-fluid"/>
                  </div>
                </div>
                <div class="row mb-2 text-secondary">
                  <div class="col">
                    Nora biedt bovendien uitgebreide mogelijkheden
                    voor dossiervoering volgens methodisch handelen, zodat je altijd de juiste informatie bij de hand hebt om de
                    best mogelijke zorg te bieden.
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col">
                    <img src="@/assets/check-24.png" alt="logo" class="img-fluid" style="width: 24px;"/>
                    Intake volgens methodisch handelen
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col">
                    <img src="@/assets/check-24.png" alt="logo" class="img-fluid" style="width: 24px;"/>
                    Unieke vragenlijsten registratie
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col">
                    <img src="@/assets/check-24.png" alt="logo" class="img-fluid" style="width: 24px;"/>
                    Taken volgsysteem
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col">
                    <img src="@/assets/check-24.png" alt="logo" class="img-fluid" style="width: 24px;"/>
                    (Sub)doelen evaluaties
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col">
                    <img src="@/assets/check-24.png" alt="logo" class="img-fluid" style="width: 24px;"/>
                    Documenten koppelen
                  </div>
                </div>
                <div class="row mb-2 sticky-bottom-left">
                  <div class="col"/>
                  <div class="col" @click="showInvoicingModal('dossier')">
                    <div class="read-more">
                      Lees meer
                      <img src="@/assets/next-20.png" alt="" class="img-fluid"/>
                    </div>
                  </div>
                  <div class="col"/>
                </div>
              </div>
            </div>
          </div>

          <!-- Overzichten -->
          <div class="col-sm-4">
            <div class="card mb-3 shadow" @mouseover="selectedTile = 5" @mouseout="selectedTile = -1" style="min-height: 450px;">
              <div class="card-header p-1 opacity-75" :style="selectedTile === 5 ? 'background-color:#003C82;' : 'background-color: #12A0D7'">
                <h5 style="margin-bottom: 2px; color: white;">
                  Overzichten
                </h5>
              </div>
              <div class="card-body" :class="selectedTile === 5 ? 'schedule-tile' : 'general-tile'" @click="showInvoicingModal('overview')">
                <div class="row mb-2">
                  <div class="col">
                    <img src="@/assets/graph-48.png" alt="" class="img-fluid"/>
                  </div>
                </div>
                <div class="row mb-2 text-secondary">
                  <div class="col">
                    Maar dat is nog niet alles. Nora gaat verder dan alleen het beheren van je praktijk. Het biedt ook tools voor
                    evidence-based resultaten, waarmee je de effectiviteit van je behandelingen kunt volgen en verbeteren.
                    Met Nora ben je altijd up-to-date en kun je beslissingen nemen op basis van de meest recente gegevens.
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col">
                    <img src="@/assets/check-24.png" alt="logo" class="img-fluid" style="width: 24px;"/>
                    Omzet overzicht
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col">
                    <img src="@/assets/check-24.png" alt="logo" class="img-fluid" style="width: 24px;"/>
                    Evidence based resultaten
                  </div>
                </div>
                <div class="row mb-2 sticky-bottom-left">
                  <div class="col"/>
                  <div class="col" @click="showInvoicingModal('overview')">
                    <div class="read-more">
                      Lees meer
                      <img src="@/assets/next-20.png" alt="" class="img-fluid"/>
                    </div>
                  </div>
                  <div class="col"/>
                </div>
              </div>
            </div>
          </div>

          <!-- Clienten -->
          <div class="col-sm-4">
            <div class="card mb-3 shadow" @mouseover="selectedTile = 2" @mouseout="selectedTile = -1" style="min-height: 450px;">
              <div class="card-header p-1 opacity-75" :style="selectedTile === 2 ? 'background-color: #003C82;' : 'background-color: #12A0D7'">
                <h5 style="margin-bottom: 2px; color: white;">
                  Cliënten
                </h5>
              </div>
              <div class="card-body" :class="selectedTile === 2 ? 'schedule-tile' : 'general-tile'" @click="showInvoicingModal('client')">
                <div class="row mb-2">
                  <div class="col">
                    <img src="@/assets/person-48.png" alt="" class="img-fluid"/>
                  </div>
                </div>
                <div class="row mb-2 text-secondary">
                  <div class="col">
                    Met Nora wordt clientbeheer een fluitje van een cent. Nora biedt u de mogelijkheid om moeiteloos
                    clientgegevens bij te houden, aanmeldlijsten te beheren, terugbelafspraken in te plannen,
                    klachten snel te registreren en incidenten nauwlettend te volgen.
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col">
                    <img src="@/assets/check-24.png" alt="logo" class="img-fluid" style="width: 24px;"/>
                    Cliëntenbeheer
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col">
                    <img src="@/assets/check-24.png" alt="logo" class="img-fluid" style="width: 24px;"/>
                    Aanmeldlijst / terugbelafspraken
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col">
                    <img src="@/assets/check-24.png" alt="logo" class="img-fluid" style="width: 24px;"/>
                    Klachten- en incidenten registratie
                  </div>
                </div>
                <div class="row mb-2 sticky-bottom-left">
                  <div class="col"/>
                  <div class="col" @click="showInvoicingModal('client')">
                    <div class="read-more">
                      Lees meer
                      <img src="@/assets/next-20.png" alt="" class="img-fluid"/>
                    </div>
                  </div>
                  <div class="col"/>
                </div>
              </div>
            </div>
          </div>

          <!-- Dashboard -->
          <div class="col-sm-4">
            <div class="card mb-3 shadow" @mouseover="selectedTile = 6" @mouseout="selectedTile = -1" style="min-height: 450px;">
              <div class="card-header p-1 opacity-75" :style="selectedTile === 6 ? 'background-color: #003C82;' : 'background-color: #12A0D7'">
                <h5 style="margin-bottom: 2px; color: white;">
                  Dashboard
                </h5>
              </div>
              <div class="card-body" :class="selectedTile === 6 ? 'schedule-tile' : 'general-tile'" @click="showInvoicingModal('dashboard')">
                <div class="row mb-2">
                  <div class="col">
                    <img src="@/assets/dashboard-48.png" alt="" class="img-fluid"/>
                  </div>
                </div>
                <div class="row mb-2 text-secondary">
                  <div class="col">
                    Beheer je dag als nooit tevoren met het persoonlijke dashboard van Nora Software. Bekijk je hele dag
                    overzichtelijk op één plek. Nora geeft je real-time meldingen over de processen, zodat je altijd
                    in controle bent.
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col">
                    <img src="@/assets/check-24.png" alt="logo" class="img-fluid" style="width: 24px;"/>
                    Persoonlijk dashboard
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col">
                    <img src="@/assets/check-24.png" alt="logo" class="img-fluid" style="width: 24px;"/>
                    Overzicht van de dag
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col">
                    <img src="@/assets/check-24.png" alt="logo" class="img-fluid" style="width: 24px;"/>
                    Proces monitoring
                  </div>
                </div>
                <div class="row mb-2 sticky-bottom-left">
                  <div class="col"/>
                  <div class="col" @click="showInvoicingModal('dashboard')">
                    <div class="read-more">
                      Lees meer
                      <img src="@/assets/next-20.png" alt="" class="img-fluid"/>
                    </div>
                  </div>
                  <div class="col"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-1 mb-5 text-secondary">
          <div class="col-sm-3"/>
          <div class="col-sm-6 text-center fs-5">
            <div class="row mt-3">
              <h2
                  style="color: #12A0D7;"
                  v-html="'Wat kost Nora software?'"
              />
            </div>
            <div class="row mt-3">
              <div class="col">
                <div class="row">
                  <div class="col">
                    <div>Wij houden het graag simpel. Voor Nora Software betaal je</div>
                    <div class="fw-bold m-2">&euro; 49,50 incl. BTW</div>
                    <div>aan maandelijkse licentiekosten.</div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="h3 m-2 ps-5 pe-5"><img src="@/assets/n-divider.png" alt="" class="img-fluid opacity-50"></div>
                    <div class="row">
                      <div class="col">
                        <div class="fw-bold">Inrichtingskosten?</div>
                        <div class="text-primary opacity-50">Daar doen wij niet aan, dat doen wij geheel kostenloos.</div>
                      </div>
                    </div>
                    <div class="h3 m-2 ps-5 pe-5"><img src="@/assets/n-divider.png" alt="" class="img-fluid opacity-50"></div>
                    <div class="row">
                      <div class="col">
                        <div class="fw-bold">Opleiding?</div>
                        <div class="text-primary opacity-50">
                          Wij vertellen graag over de features in Nora Software. Sowieso inbegrepen is een inhoudelijke opleiding over agenda- en dossiergebruik
                          en een opleiding over het factureren.
                        </div>
                      </div>
                    </div>
                    <div class="h3 m-2 ps-5 pe-5"><img src="@/assets/n-divider.png" alt="" class="img-fluid opacity-50"></div>
                    <div class="row">
                      <div class="col">
                        <div class="fw-bold">Servicedesk?</div>
                        <div class="text-primary opacity-50">
                          Ook hier zitten geen extra kosten aan verbonden. De servicedesk is bereikbaar per telefoon en email.
                        </div>
                      </div>
                    </div>
                    <div class="h3 m-2 ps-5 pe-5"><img src="@/assets/n-divider.png" alt="" class="img-fluid opacity-50"></div>
                    <div class="row">
                      <div class="col">
                        <div class="fw-bold">Verborgen kosten?</div>
                        <div class="text-primary opacity-50">
                          Die zijn er niet! Maar wel:
                          <div class="mt-2"><img src="@/assets/check-24.png" alt="logo" class="img-fluid me-2" style="width: 24px;"/>Regelmatige updates</div>
                          <div><img src="@/assets/check-24.png" alt="logo" class="img-fluid me-2" style="width: 24px;"/>Een opzegtermijn van 1 maand</div>
                          <div><img src="@/assets/check-24.png" alt="logo" class="img-fluid me-2" style="width: 24px;"/>Automatische backups</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div>
              <NoraBubble :message="'De licentie van Nora Software is maandelijks &euro;49,50 inclusief BTW.'" :top="100"/>
            </div>
          </div>
        </div>
        <div class="row mb-5 text-secondary">
          <div class="col-sm-3"/>
          <div class="col-sm-6 text-center fs-5">
            <div class="row mt-3">
              <h2
                  style="color: #12A0D7;"
                  v-html="'Voor wie is Nora software?'"
              />
            </div>
            <div class="row mt-3">
              <div class="col">
                Of je nu een specialist bent in haptotherapie, relatietherapie, zwangerschapsbegeleiding, fysio- of manueeltherapie,
                Nora software is ontwikkeld om jouw dagelijkse werkzaamheden te stroomlijnen.
                Met aangepaste intakeformulieren die zijn toegespitst op elke specifieke discipline.
              </div>
            </div>
          </div>
          <div class="col-sm-3"/>
        </div>
        <div class="row mt-1 mb-5 text-secondary">
          <div class="col-sm-3"/>
          <div class="col-sm-6 text-center fs-5">
            <div class="row mt-3">
              <h2
                  style="color: #12A0D7;"
                  v-html="'Waarom Nora software?'"
              />
            </div>
            <div class="row mt-3">
              <div class="col">
                Kortom, Nora-software is dé partner die je nodig hebt om je praktijk efficiënter te runnen, zodat je meer
                tijd kunt besteden aan datgene waar je het beste in bent: het zorgen voor je cliënten.
                Sluit je aan bij de groeiende gemeenschap van zorgverleners die al profiteren van de voordelen van Nora en
                ontdek hoe ook jij je praktijk naar een hoger niveau kunt tillen.
              </div>
            </div>
          </div>
          <div class="col-sm-3"/>
        </div>

      </div>
  </div>



</template>

<script>
import demoModalService from "@/services/modals/demoModalService";
import scheduleModalService from "@/services/modals/scheduleModalService";
import invoicingModalService from "@/services/modals/invoicingModalService";
import dossierSlides from "@/slides/dossierSlides";
import invoicingSlides from "@/slides/invoicingSlides";
import overviewSlides from "@/slides/overviewSlides";
import scheduleSlides from "@/slides/scheduleSlides";
import clientSlides from "@/slides/clientSlides";
import dashboardSlides from "@/slides/dashboardSlides";
import NoraBubble from "@/components/nora/NoraBubble";

export default {
  name: "main",
  components: {NoraBubble},
  data() {
    return {
      selectedTile: -1,
    }
  },
  methods: {
    showDemoModal() {
      demoModalService.show()
    },
    showScheduleModal() {
      scheduleModalService.show()
    },
    showInvoicingModal(slideType) {
      let slides = []
      let title = ''
      switch (slideType){
        case 'invoicing': {
          title = 'Factureren'
          slides = invoicingSlides
          break
        }
        case 'dossier': {
          title = 'Dossier'
          slides = dossierSlides
          break
        }
        case 'overview': {
          title = 'Overzichten'
          slides = overviewSlides
          break
        }
        case 'schedule': {
          title = 'Agenda'
          slides = scheduleSlides
          break
        }
        case 'client': {
          title = 'Client'
          slides = clientSlides
          break
        }
        case 'dashboard': {
          title = 'Persoonlijk dashboard'
          slides = dashboardSlides
        }
      }
      invoicingModalService.show(title, slides)
    },
  }
}
</script>

<style scoped>
.general-tile {
  border-radius: 5px;
  color: #12A0D7;
  background-color: white;
}
.schedule-tile {
  color: #003C82;
  background-color: rgba(51,96,140,0.08);
}
.client-tile {
  color: #9768a5;
  background-color: rgba(151,104,165,0.08);
}
.dossier-tile {
  color: #e7718a;
  background-color: rgba(231,113,138,0.08);
}
.invoice-tile {
  color: #f6ba57;
  background-color: rgba(246,186,87,0.08);
}
.overview-tile {
  color: #ed7846;
  background-color: rgba(237,120,70,0.08);
}
.dashboard-tile {
  color: #d54c45;
  background-color: rgba(213,76,69,0.08);
}
.read-more:hover {
  font-family: 'Nunito';
  border-bottom: solid 2px #12A0D7;
  font-weight: bold;
}
.sticky-bottom-left {
  position: absolute;
  bottom: 0;
  width: auto;
  display: block;
}
.h3 {
  margin: 0;
  display: grid;
  width: 100%;
  align-items: center;
  text-align: center;
  grid-template-columns: minmax(20px, 1fr) auto minmax(20px, 1fr);
  grid-gap: 20px;
  font-weight: 400;
  font-size: 28px;
  color: #818078;
}

.h3:before,
.h3:after {
  content: '';
  height: 1px;
}

.h3:after {
  background: linear-gradient(to right, transparent, #818078);
}

.h3:before {
  background: linear-gradient(to right, #818078, transparent);
}
</style>
<template>
  <div v-if="noraModalService.state.active" class="modal show" id="noraModal" tabindex="-1" role="dialog" style="display:block">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="container text-secondary">
            <div class="row mb-3">
              <div class="col text-end">
                <button type="button" class="btn-close" @click="hideModal()"></button>
              </div>
            </div>

            <div class="row mt-1 mb-3 text-secondary">
              <div class="col-sm-2"/>
              <div class="col-sm-6 mt-3 text-start">
                <div>
                  <div class="bubble bubble-bottom-left z-1">
                    Hoi... ik ben Nora, een webbased applicatie voor de contractloze zorg.
                  </div>
                  <img src="@/assets/nora.png" alt="" class="img-fluid" style="border-radius: 50%;"/>
                </div>
              </div>
              <div class="col-sm-2"/>
            </div>






          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-show="noraModalService.state.active" class="dialog-bg"></div>
</template>

<script>
import noraModalService from "@/services/modals/noraModalService";

export default {
  name: "NoraModal",
  data() {
    return {
      noraModalService,
    };
  },
  methods: {
    hideModal() {
      noraModalService.cancel()
    },
  },
}
</script>

<style scoped>
.bubble {
  position: relative;
  left: 80px;
  font-size: 14px;
  width: 200px;
  background: #12A0D7;
  border-radius: 20px;
  border: solid 1px #12A0D7;
  padding: 12px;
  text-align: center;
  color: #fff;
}

.bubble-bottom-left:before {
  content: "";
  width: 1px;
  height: 1px;
  position: absolute;
  border-left: 12px solid #12A0D7;
  border-right: 12px solid transparent;
  border-top: 12px solid #12A0D7;
  border-bottom: 20px solid transparent;
  left: 32px;
  bottom: -24px;
}
</style>
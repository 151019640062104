<template>
  <div :style="'margin-top:' + top + 'px; margin-left:' + left + '%;'">
    <div class="bubble bubble-bottom-left">
      <div class="row">
        <div class="col fw-bold">
          {{ title }}
        </div>
      </div>
      <div class="row">
        <div class="col">
          {{ message }}
        </div>
      </div>
    </div>
    <div style="text-align: left;">
      <img src="@/assets/nora.png" alt="" class="img-fluid nora" :class="left < 40 ? 'mirror' : ''" style="border-radius: 50%;"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoraBubble",
  props: {
    title: String,
    message: String,
    left: Number,
    top: Number,
  }
}
</script>

<style scoped>
.nora {
  position: relative;
}

.mirror {
  -moz-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.bubble {
  position: relative;
  left: 80px;
  font-size: 14px;
  width: 200px;
  background: #12A0D7;
  border-radius: 20px;
  border: solid 1px #12A0D7;
  padding: 12px;
  text-align: center;
  color: #fff;
  z-index: 2;
}

.bubble-bottom-left:before {
  content: "";
  width: 1px;
  height: 1px;
  position: absolute;
  border-left: 12px solid #12A0D7;
  border-right: 12px solid transparent;
  border-top: 12px solid #12A0D7;
  border-bottom: 20px solid transparent;
  left: 32px;
  bottom: -24px;
}
</style>
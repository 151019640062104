<template>
  <div class="shapedividers_com-281">
    <div class="container-fluid" style="color: #003C82;">
      <div class="row mt-2">
        <div class="col">
          <img src="@/assets/nora-logo.png" alt="logo" class="img-fluid" style="width: 240px;"/>
        </div>
      </div>
      <div class="row mt-1 mb-3 text-secondary">
        <div class="col-sm-4"/>
        <div class="col-sm-4 text-center fs-5">
          <div class="row mt-3" @mouseover="showHeart = true" @mouseleave="showHeart = false">
            <h2
                style="color: #12A0D7;"
                v-html="showHeart ? 'Software met een &#128150; voor jou en je cliënt' : 'Software met een hart voor jou en je cliënt'"
            />
          </div>
          <div class="row mt-3">

            <div class="col">
              Met Nora software in je praktijk hoef je nooit meer kostbare tijd te verspillen aan tijdrovende administratieve taken.
              We begrijpen dat je prioriteit ligt bij het leveren van de best mogelijke zorg aan je cliënten,
              en daarom hebben we Nora ontwikkeld: dé oplossing om je te helpen bij elke stap van je praktijkbeheer.
            </div>
          </div>
          <div class="row mt-3">
            <div class="col">
              <social-tile/>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col">

            </div>
          </div>
          <div class="row mt-3">
            <div class="col">

            </div>
          </div>
          <div class="row mt-3">
            <div class="col">

            </div>
          </div>
        </div>
        <div class="col-sm-4 mt-3 text-start">
          <div>
            <div class="bubble bubble-bottom-left" @click="showNoraModal()">
              Hoi ik ben Nora, een webbased applicatie voor contractloze zorg.
            </div>
            <img src="@/assets/nora.png" alt="" class="img-fluid" style="border-radius: 50%;" @click="showNoraModal()"/>
          </div>
        </div>
      </div>

      <NoraModal/>
    </div>
  </div>
</template>

<script>
import noraModalService from "@/services/modals/noraModalService";
import NoraModal from "@/components/nora/NoraModal";
import SocialTile from "@/components/socialTile";

export default {
  name: "HeaderNewTile",
  components: {SocialTile, NoraModal},
  data() {
    return {
      showHeart: false,
    }
  },
  methods: {
    showNoraModal() {
      noraModalService.show()
    }
  }
}
</script>

<style scoped>
.bubble {
  position: relative;
  left: 80px;
  font-size: 14px;
  width: 200px;
  background: #12A0D7;
  border-radius: 20px;
  border: solid 1px #12A0D7;
  padding: 12px;
  text-align: center;
  color: #fff;
}

.bubble-bottom-left:before {
  content: "";
  width: 1px;
  height: 1px;
  position: absolute;
  border-left: 12px solid #12A0D7;
  border-right: 12px solid transparent;
  border-top: 12px solid #12A0D7;
  border-bottom: 20px solid transparent;
  left: 32px;
  bottom: -24px;
}

.shapedividers_com-281{
  overflow:hidden;
  position:relative;
}
.shapedividers_com-281::before{
  content:'';
  position: absolute;
  bottom: -1px;
  left: -1px;
  right: -1px;
  top: -1px;
  z-index: 3;
  opacity: 0.3;
  pointer-events: none;
  background-repeat: no-repeat;
  background-size: 100% 32px;
  background-position: 50% 100%;
  background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.28 2.17" preserveAspectRatio="none"><path d="M35.28 1.67c-3.07-.55-9.27.41-16.15 0-6.87-.4-13.74-.58-19.13.1v.4h35.28z" fill="%232ea0bd"/><path d="M35.28 1.16c-3.17-.8-7.3.4-10.04.56-2.76.17-9.25-1.47-12.68-1.3-3.42.16-4.64.84-7.04.86C3.12 1.31 0 .4 0 .4v1.77h35.28z" opacity=".5" fill="%232ea0bd"/><path d="M35.28.31c-2.57.84-7.68.3-11.8.43-4.1.12-6.85.61-9.57.28C11.18.69 8.3-.16 5.3.02 2.3.22.57.85 0 .87v1.2h35.28z" opacity=".5" fill="%232ea0bd"/></svg>');
}

@media (min-width:768px){
  .shapedividers_com-281::before{
    background-size: 128% 151px;
    background-position: 50% 100%;
  }
}

@media (min-width:1025px){
  .shapedividers_com-281::before{
    bottom: -0.1vw;
    left: -0.1vw;
    right: -0.1vw;
    top: -0.1vw;
    background-size: 100% 65px;
    background-position: 50% 100%;
    transform: rotateY(180deg);
    background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.28 2.17" preserveAspectRatio="none"><path d="M35.28 1.67c-3.07-.55-9.27.41-16.15 0-6.87-.4-13.74-.58-19.13.1v.4h35.28z" fill="%2312a0d7"/><path d="M35.28 1.16c-3.17-.8-7.3.4-10.04.56-2.76.17-9.25-1.47-12.68-1.3-3.42.16-4.64.84-7.04.86C3.12 1.31 0 .4 0 .4v1.77h35.28z" opacity=".5" fill="%2312a0d7"/><path d="M35.28.31c-2.57.84-7.68.3-11.8.43-4.1.12-6.85.61-9.57.28C11.18.69 8.3-.16 5.3.02 2.3.22.57.85 0 .87v1.2h35.28z" opacity=".5" fill="%2312a0d7"/></svg>');
  }
}
@media (min-width:2100px){
  .shapedividers_com-281::before{
    background-size: 100% calc(2vw + 65px);
  }
}

</style>
<template>
  <div class="container-fluid" style="color: #003C82;">
    <div class="row mt-3">
      <div class="col-sm-5">
        <DemoTile/>
      </div>
      <div class="col-sm-2">
        <div class="cardd text-secondary">
          <div class="card-body ms-3">
            <div class="row mb-2 text-start fw-bold">
              <div class="col" style="color: #12A0D7;">
                Nora Software
              </div>
            </div>
            <div class="row mb-2 text-start">
              <div class="col">
                <img src="@/assets/mail-24.png" alt="mail" class="img-fluid"/>
                info@nora-software.nl
              </div>
            </div>
            <div class="row mb-2 text-start">
              <div class="col">
                <img src="@/assets/phone-24.png" alt="phone" class="img-fluid"/>
                (06) 27 23 74 19
              </div>
            </div>
            <div class="row mt-4 text-start">
              <div class="col footer-link" @click="showNoraUitgelichtModal()">
                <img src="@/assets/nora-icon-32.png" alt="nora" class="img-fluid" style="width: 24px; height: 24px;"/>
                Nora uitgelicht
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="col-sm-2">
        <div class="cardd text-secondary">
          <div class="card-body ms-3">
            <div class="row mb-2 text-start fw-bold">
              <div class="col" style="color: #12A0D7;">
                Voor wie?
              </div>
            </div>
            <div class="row mb-2 text-start">
              <div class="col">
                Haptotherapeuten
              </div>
            </div>
            <div class="row mb-2 text-start">
              <div class="col">
                Relatietherapeuten
              </div>
            </div>
            <div class="row mb-2 text-start">
              <div class="col">
                Zwangerschapsbegeleiding
              </div>
            </div>
            <div class="row mb-2 text-start">
              <div class="col">
                Fysiotherapeuten (contractloos)
              </div>
            </div>
            <div class="row mb-2 text-start">
              <div class="col">
                Manueeltherapeuten (contractloos)
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="col-sm-2">
        <div class="cardd text-secondary">
          <div class="card-body ms-3">
            <div class="row mb-2 text-start fw-bold">
              <div class="col footer-link" style="color: #12A0D7;">
                Functies
              </div>
            </div>
            <div class="row mb-2 text-start" @click="showScheduleModal()">
              <div class="col footer-link">
                Agenda
              </div>
            </div>
            <div class="row mb-2 text-start">
              <div class="col footer-link">
                Clientbeheer
              </div>
            </div>
            <div class="row mb-2 text-start">
              <div class="col footer-link">
                Dosservoering
              </div>
            </div>
            <div class="row mb-2 text-start">
              <div class="col footer-link">
                Facturering
              </div>
            </div>
            <div class="row mb-2 text-start">
              <div class="col footer-link">
                Overzichten
              </div>
            </div>
            <div class="row mb-2 text-start">
              <div class="col footer-link">
                Dashboard
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-1"></div>

    </div>
    <div class="row mt-2">
      <div class="col text-secondary">
        <img src="@/assets/WSC_64.png" alt="" class="img-fluid me-3"/>
        Wever Software Consultancy 2024
      </div>

    </div>
  </div>

  <NoraUitgelichtModal/>
</template>

<script>
import DemoTile from "@/components/DemoTile";
import scheduleModalService from "@/services/modals/scheduleModalService";
import noraUitgelichtModalService from "@/services/modals/noraUitgelichtModalService";
import NoraUitgelichtModal from "@/components/NoraUitgelichtModal";
export default {
  name: "FooterTile",
  components: {NoraUitgelichtModal, DemoTile},
  data() {
    return {

    }
  },
  methods: {
    showScheduleModal() {
      scheduleModalService.show()
    },
    showNoraUitgelichtModal() {
      noraUitgelichtModalService.show()
    }
  }
}
</script>

<style scoped>
.footer-link:hover {
  color: #12A0D7;
}
</style>
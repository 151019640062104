<template>
    <div class="container">
      <div class="row mt-1 mb-3 text-secondary">
        <div class="col-sm-4"/>
        <div class="col-sm-4 mt-3 text-start">
          <div>
            <div class="bubble bubble-bottom-left z-1" @click="showDemoModal()">
              Heb je interesse in een demo?
            </div>
            <img src="@/assets/nora.png" alt="" class="img-fluid flip-image" style="border-radius: 50%;" @click="showDemoModal()"/>
          </div>
        </div>
        <div class="col-sm-4"/>
      </div>
    </div>
</template>

<script>
import demoModalService from "@/services/modals/demoModalService";

export default {
  name: "DemoTile",
  data() {
    return {

    }
  },
  methods: {
    showDemoModal() {
      demoModalService.show()
    },
  }
}
</script>

<style scoped>
.flip-image {
  -webkit-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
}
.bubble {
  position: relative;
  left: 80px;
  font-size: 14px;
  width: 200px;
  background: #12A0D7;
  border-radius: 20px;
  border: solid 1px #12A0D7;
  padding: 12px;
  text-align: center;
  color: #fff;
}

.bubble-bottom-left:before {
  content: "";
  width: 1px;
  height: 1px;
  position: absolute;
  border-left: 12px solid #12A0D7;
  border-right: 12px solid transparent;
  border-top: 12px solid #12A0D7;
  border-bottom: 20px solid transparent;
  left: 32px;
  bottom: -24px;
}
</style>
import { reactive } from 'vue'

const state = reactive({
    active: false,
    slides: [],
})

// -----------------------------------
// Private Methods
// -----------------------------------
let close // will hold our promise resolve function
const dialogPromise = () => new Promise((resolve) => (close = resolve))
const open = (title, slides) => {
    state.title = title
    state.slides = slides
    state.active = true
    return dialogPromise()
}
const reset = () => {
    state.active = false
}

// -----------------------------------
// Public interface
// -----------------------------------

const invoicingModalService = {
    get state() {
        return state
    },
    show(title, slides) {
        return open(title, slides)
    },
    cancel() {
        close(false)
        reset()
    },
}

export default invoicingModalService
<template>
  <div v-if="demoModalService.state.active" class="modal show" id="demoModal" tabindex="-1" role="dialog" style="display:block">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="custom-shape-divider-bottom-1695718505 opacity-50">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
              <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
              <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
            </svg>
          </div>
          <div class="container text-secondary">
            <div class="row mb-3">
              <div class="col-1"/>
              <div class="col">
                <h5 class="card-title" style="color: #4A90E2;">Demo aanvraag Nora-software</h5>
              </div>
              <div class="col-1">
                <button type="button" class="btn-close" @click="hideModal()"></button>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col small">
                Wat leuk dat je geinteresseerd bent in een demo van Nora-software. Hiervoor hebben wij wel bepaalde gegevens nodig.
              </div>
            </div>
            <form class="form-group" @submit.prevent="sendDemoRequest()">
              <div class="row mb-2">
                <div class="col">
                  <div class="row mb-0 text-secondary text-start small">
                    <label for="inputName" class="col col-form-label">Wat is je naam?</label>
                  </div>
                  <div class="row">
                    <input type="text" class="col ms-2 me-2 form-control text-secondary required" id="inputName" v-model="form.name" required/>
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col">
                  <div class="row mb-0 text-secondary text-start small">
                    <label for="inputInstitute" class="col col-form-label">Wat is je praktijknaam?</label>
                  </div>
                  <div class="row">
                    <input type="text" class="col ms-2 me-2 form-control text-secondary required" id="inputInstitute" v-model="form.institute" required/>
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col">
                  <div class="row mb-0 text-secondary text-start small">
                    <label for="inputEmail" class="col col-form-label">Hoe wil je dat we contact met je opnemen?</label>
                  </div>
                  <div class="row">
                    <div class="col text-secondary text-start small">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input opacity-75" type="radio" name="inlineGender" id="contact1" value="phone" v-model="form.contact">
                        <label class="form-check-label" for="contact1">Telefonisch</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input opacity-75" type="radio" name="inlineGender" id="contact2" value="email" v-model="form.contact">
                        <label class="form-check-label" for="contact2">E-mail</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="form.contact === 'email'" class="row mb-2">
                <div class="col">
                  <div class="row mb-0 text-secondary text-start small">
                    <label for="inputEmail" class="col col-form-label">Wat is je e-mailadres?</label>
                  </div>
                  <div class="row">
                    <input type="email" class="col ms-2 me-2 form-control text-secondary required" id="inputEmail" v-model="form.email" :required="form.contact === 'email'"/>
                  </div>
                </div>
              </div>
              <div v-if="form.contact === 'phone'" class="row mb-2">
                <div class="col">
                  <div class="row mb-0 text-secondary text-start small">
                    <label for="inputPhone" class="col col-form-label">Wat is je telefoonnummer?</label>
                  </div>
                  <div class="row">
                    <input type="tel" class="col ms-2 me-2 form-control text-secondary required" id="inputPhone" v-model="form.phone" :required="form.contact === 'phone'"/>
                  </div>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col text-start">
                  <button type="submit" class="btn btn-sm btn-primary opacity-75">Neem contact met mij op voor een demo</button>
                </div>
              </div>


            </form>

          </div>
        </div>

        <!--div class="modal-footer bg-light pt-1 pb-1">
          <button type="button" class="btn btn-secondary opacity-75" @click="hideModal()">Sluiten</button>
        </div-->
      </div>
    </div>
  </div>

  <div v-show="demoModalService.state.active" class="dialog-bg"></div>
</template>

<script>
import demoModalService from "@/services/modals/demoModalService";
import db from "@/firebase/init";
import { addDoc, collection } from "firebase/firestore"

const captchaUrl = '/.netlify/functions/captcha'

export default {
  name: "DemoModal",
  data() {
    return {
      form: {
        name: '',
        institute: '',
        contact: 'phone',
        email: '',
        phone: '',

      },
      demoModalService,
    };
  },
  methods: {
    hideModal() {
      this.form.name = ''
      this.form.institute = ''
      this.form.email = ''
      this.form.phone = ''

      demoModalService.cancel()
    },
    validate() {
      let result = true

      if (this.form.name === '') {
        result = false
      }
      if (this.form.institute === '') {
        result = false
      }
      if (this.form.contact === 'email' && this.form.email === '') {
        result = false
      }
      if (this.form.contact === 'phone' && this.form.phone === '') {
        result = false
      }

      return result
    },
    async sendDemoRequest() {
      if (this.validate() === true) {
        await this.$recaptchaLoaded()
        const token = await this.$recaptcha('login')
        const response = await fetch(`${captchaUrl}?rt=${token}`);

        if (!response.ok) {
          console.error('Captcha failed', response);
          this.hideModal()
          return;
        }

        const emailObj = {
          to: 'arnold@nora-software.nl',
          from: 'Website Nora software <info@nora-software.nl>',
          message: {
            subject: 'Demo aanvraag Nora software',
            html: 'Goedemorgen, <br><br>Er is een demo aanvraag vanuit de website verstuurd:<br><br>'+
                'Naam: <b>' + this.form.name + '</b><br>' +
                'Praktijk: <b>'+ this.form.institute + '</b><br>' +
                'Contact via: <b>' + (this.form.contact === 'phone' ? 'Telefoon' : 'Email') + '</b><br>' +
                'Email: <b>' + this.form.email + '</b><br>' +
                'Mobiel: <b>' + this.form.phone + '</b><br><br>' +
                'Fijne dag verder!<br><br>' +
                'Groeten van de Nora software website',
          }
        }

        const colRef = collection(db, 'mail')

        await addDoc(colRef, emailObj).then((doc) => {
          console.log(doc.id)
        }).catch((e) => {
          console.log(e)
        })

        this.hideModal()
      }



    },
  },
}
</script>

<style scoped>
.custom-shape-divider-bottom-1695718505 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1695718505 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 64px;
}

.custom-shape-divider-bottom-1695718505 .shape-fill {
  fill: #4A90E2;
}

.required {
  border-bottom: 3px solid rgba(25, 159, 214, 0.8);
}
</style>

const scheduleSlides = [
    {
        title: 'Agenda',
        content: 'De agenda is visueel aantrekkelijk en overzichtelijk dankzij het gebruik van kleurcodering voor afspraken en locaties.',
        image: require('@/assets/schedule/Agenda.png'),
        left: 55,
        top: 48,
    },
    {
        title: 'Afspraak',
        content: 'Het plannen van een afspraak is een fluitje van een cent.',
        image: require('@/assets/schedule/Afspraak.png'),
        left: 20,
        top: 64,
    },
    {
        title: 'Afspraakbevestiging',
        content: 'Met de handige sjabloonfunctionaliteit kun je zelf op een eenvoudige en persoonlijke manier afspraakbevestigingen creëren.',
        image: require('@/assets/schedule/Afspraakbevestiging.png'),
        left: 70,
        top: 32,
    },
    {
        title: 'Repeterende afspraken',
        content: 'Daarnaast is het mogelijk om regelmatig terugkerende afspraken in te plannen, voor nog meer gemak.',
        image: require('@/assets/schedule/Repeterend.png'),
        left: 50,
        top: 12,
    },
]

export default scheduleSlides
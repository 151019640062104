import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { VueReCaptcha } from 'vue-recaptcha-v3';
import VuePlyr from 'vue-plyr';
import 'vue-plyr/dist/vue-plyr.css';

createApp(App)
    .use(VueReCaptcha, {siteKey: process.env.VUE_APP_RECAPTCHA_V3_KEY})
    .use(VuePlyr, {
        plyr: {},
    })
    .mount('#app')

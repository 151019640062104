<template>
  <div v-if="invoicingModalService.state.active" class="modal modal-lg show" id="invoicingModal" tabindex="-1" role="dialog" style="display:block; font-family: 'Nunito', sans-serif;">
    <div class="modal-dialog modal-xl modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body ps-0 pe-0 overflow-auto" style="min-height: 600px; max-height: 600px;">
          <div class="container ps-0 pe-0 overflow-x-hidden text-secondary" style="font-family: 'Nunito', system-ui; font-size: 16px;">
            <div class="row mb-3 sticky-top">
              <div class="col-2"/>
              <div class="col">
                <h5 class="card-title fw-bold" style="color: #4A90E2;">{{ invoicingModalService.state.title }}</h5>
              </div>
              <div class="col-2">
                <button type="button" class="btn-close" @click="hideModal()"></button>
              </div>
            </div>
            <div class="row mb-0">
              <div v-if="isMobile() === false" class="col">
                <vueper-slides autoplay fade bullets-outside class="no-shadow" duration="5000">
                  <vueper-slide
                      v-for="(slide, i) in slides"
                      :key="i"
                      :title="slide.title"
                      :content="slide.content"
                      :image="slide.image"
                  >
                    <template #content>
                      <i class="icon icon-check"></i>
                      <div class="row">
                        <div class="col">
                          <NoraBubble :title="slide.title" :message="slide.content" :left="slide.left" :top="slide.top"/>
                        </div>
                      </div>
                    </template>

                  </vueper-slide>
                </vueper-slides>
              </div>
              <div v-else class="col">
                <vueper-slides autoplay fade bullets-outside :fixed-height="'300px'" :arrows="false" class="no-shadow" duration="5000">
                  <vueper-slide
                      v-for="(slide, i) in slides"
                      :key="i"
                      :title="slide.title"
                      :content="slide.content"
                  >
                    <template #content>
                      <div class="row mb-2">
                        <div class="col-3">
                          <img src="@/assets/nora.png" alt="" class="img-fluid nora" :class="slide.left < 40 ? 'mirror' : ''" style="border-radius: 50%; margin-left: 16px;"/>
                        </div>
                        <div class="col-8">
                          <div class="row">
                            <div class="col fw-bold text-left">
                              {{ slide.title }}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col ms-2 me-2">
                              {{ slide.content }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <img :src="slide.image" alt="slide.content" class="img-fluid"/>
                        </div>
                      </div>

                    </template>

                  </vueper-slide>
                </vueper-slides>
              </div>


            </div>

            <div class="row mb-2">
              <div class="col ps-4 pe-4 text-secondary">
                Maar dit is nog lang niet alles... Neem voor meer informatie contact op met Nora software!
              </div>
            </div>
            <div class="row mt-3 mb-0 text-secondary">
              <div class="col">
                <button type="button" class="btn btn-primary opacity-50" @click="showDemoModal()">Vraag een demo aan</button>
              </div>
            </div>
          </div>
        </div>

        <!--div class="modal-footer bg-light pt-1 pb-1">
          <button type="button" class="btn btn-secondary opacity-75" @click="hideModal()">Sluiten</button>
        </div-->
      </div>
    </div>
  </div>

  <div v-show="invoicingModalService.state.active" class="dialog-bg"></div>
</template>

<script>
import demoModalService from "@/services/modals/demoModalService";
import invoicingModalService from "@/services/modals/invoicingModalService";
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import NoraBubble from "@/components/nora/NoraBubble";

export default {
  name: "InvoicingModal",
  components: {
    NoraBubble,
    VueperSlides,
    VueperSlide,
  },
  props: {
    slides: Array,
  },
  data() {
    return {
      invoicingModalService,
    };
  },
  methods: {
    hideModal() {
      invoicingModalService.cancel()
    },
    isMobile2() {
      return (screen.width <= 760)
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    showDemoModal() {
      invoicingModalService.cancel()
      demoModalService.show()
    },
  },
}
</script>

<style>
.vueperslides__arrow {
  color: #4A90E2;
}
.mirror {
  -moz-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
</style>
const noraUitgelicht = [
    {
        title: 'Nora uitgelicht #9',
        content: 'Communicatie is belangrijk. Naar de cliënt, naar de huisarts, naar de verwijzer of een andere ' +
            'zorgverlener. In Nora Software kan je berichten versturen, berichtsjablonen maken, berichtsjablonen ' +
            'koppelen aan een taak zodat je niet meer vergeet om een bericht te versturen. Berichten worden geopend ' +
            'door de ontvanger in een beveiligd berichtportaal, waarin je eigen praktijklogo zichtbaar gemaakt kan worden.',
        youtube: 'AksBSi3pqMo',
    },
    {
        title: 'Nora uitgelicht #8',
        content: 'Losse aanmeldlijsten, geeltjes voor je terugbelafspraken, klinkt dit bekend? Met Nora Software is ' +
            ' dit verleden tijd. In Nora Software heb je overzichtelijke lijsten voor aanmeldingen en terugbelafspraken, ' +
            'met signalering op je persoonlijke dashboard als er actie moet worden genomen. Vanzelfsprekend is het aanmaken ' +
            'van een aanmelding of terugbelafspraak zeer eenvoudig en op vele plekken mogelijk.',
        youtube: 'OjAJmjxRVQ4',
    },
    {
        title: 'Nora uitgelicht #7',
        content: 'Samen met je client behandeldoelen stellen en daarna de progressie monitoren, met Nora Software kan' +
            ' dit. Je wordt geholpen bij het stellen van de doelen door gebruik van vragenlijsten, maar natuurlijk ' +
            'kan je deze ook zelf aanmaken. Evalueren kan met één muisklik en de progressie is meteen zichtbaar.',
        youtube: 'UqVFCviorww',
    },
    {
        title: 'Nora uitgelicht #6',
        content: 'Helaas komt het soms voor... klachten. Liever natuurlijk niet, maar dankzij de klachtenregistratie' +
            ' in Nora Software blijf je het overzicht houden, waardoor de klachten snel en efficiënt worden opgelost.',
        youtube: 'cD2i2oYsejo',
    },
    {
        title: 'Nora uitgelicht #5',
        content: 'Wil je dagelijks of wekelijks factureren? In Nora Software kan je factureren vanuit je agenda. '+
             'Stel in de agenda de filters in voor de afspraken die je wilt declareren en een paar klikken ' +
             'verder zijn je facturen verstuurd naar je clienten.',
        youtube: 'nQ24ahHgIGI',
    },
    {
        title: 'Nora uitgelicht #4',
        content: 'Moeite met vragenlijsten uitzetten en vind je data verzamelen maar lastig? Met Nora software ' +
            'wordt het allemaal veel eenvoudiger. Vragenlijsten worden automatisch aan het dossier gekoppeld ' +
            'en Nora software geeft een seintje als deze ingevuld moeten worden. Na het invullen door de client ' +
            'staat de vragenlijst in het dossier en is de data verzameld!',
        youtube: '16As6IFiUlA',
    },
    {
        title: 'Nora uitgelicht #3',
        content: 'In deze aflevering van "Nora uitgelicht" aandacht voor de agenda.',
        youtube: 'wvIJVD-o784',
    },
    {
        title: 'Nora uitgelicht #2',
        content: 'In deze aflevering van "Nora uitgelicht" een stukje van het cliënt dossier scherm.',
        youtube: 'JL1njNp6A3M',
    },
    {
        title: 'Nora uitgelicht #1',
        content: 'In de nieuwe reeks genaamd "Nora uitgelicht" zullen we aan de hand van korte filmpjes bijzondere '+
            'kenmerken en functionaliteiten van Nora Software belichten. Tijdens deze serie krijg je meer inzicht '+
            'in de veelzijdige mogelijkheden die Nora Software biedt.',
        youtube: '-PbBOIgxCKo',
    },
]

export default noraUitgelicht
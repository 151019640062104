<template>
  <div v-if="scheduleModalService.state.active" class="modal modal-lg show" id="scheduleModal" tabindex="-1" role="dialog" style="display:block; font-family: 'Nunito', sans-serif;">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body ps-0 pe-0 overflow-auto" style="min-height: 600px; max-height: 600px;">
          <div class="container ps-0 pe-0 overflow-x-hidden text-secondary" style="font-family: 'Nunito', system-ui; font-size: 16px;">
            <div class="row mb-3 sticky-top">
              <div class="col-2"/>
              <div class="col">
                <h5 class="card-title fw-bold" style="color: #4A90E2;">Agenda</h5>
              </div>
              <div class="col-2">
                <button type="button" class="btn-close" @click="hideModal()"></button>
              </div>
            </div>
            <div class="row mb-3 justify-content-center">
              <div class="col-sm-8 ms-4 me-4">
                Geen papieren agenda of verschillende agenda systemen meer, gewoon alle afspraken overzichtelijk in 1 agenda.
              </div>
            </div>
            <div class="row mb-3">
              <div class="col">
                <ImageEffect :imageToShow="'schedule/schedule3.png'" :degrees="5"/>
              </div>
            </div>
            <div class="row mb-3 justify-content-center">
              <div class="col-sm-8 ps-4 pe-4 text-secondary">
                Een afspraak kan eenvoudig gemaakt worden. De client krijgt automatisch een afspraakbevestiging per mail.
              </div>
            </div>
            <div class="row mb-3">
              <div class="col">
                <ImageEffect :imageToShow="'schedule/schedule2.png'" :degrees="5"/>
              </div>
            </div>
            <div class="row mb-3 justify-content-center">
              <div class="col-sm-8 ps-4 pe-4 text-secondary">
                Afspraken kunnen eenvoudig doorgepland worden met behulp van een wizard.
              </div>
            </div>
            <div class="row mb-3">
              <div class="col">
                <ImageEffect :imageToShow="'schedule/schedule6.png'" :degrees="5"/>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col ps-4 pe-4 text-secondary">
                Interesse? Neem contact op met Nora software!
              </div>
            </div>
            <div class="row mt-3 mb-3 text-secondary">
              <div class="col">
                <button type="button" class="btn btn-primary opacity-50" @click="showDemoModal()">Vraag een demo aan</button>
              </div>
            </div>
          </div>
        </div>

        <!--div class="modal-footer bg-light pt-1 pb-1">
          <button type="button" class="btn btn-secondary opacity-75" @click="hideModal()">Sluiten</button>
        </div-->
      </div>
    </div>
  </div>

  <div v-show="scheduleModalService.state.active" class="dialog-bg"></div>
</template>

<script>
import scheduleModalService from "@/services/modals/scheduleModalService";
import ImageEffect from "@/components/ImageEffect";
import demoModalService from "@/services/modals/demoModalService";

export default {
  name: "ScheduleModal",
  components: {ImageEffect},
  data() {
    return {
      scheduleModalService,
    };
  },
  methods: {
    hideModal() {
      scheduleModalService.cancel()
    },
    showDemoModal() {
      scheduleModalService.cancel()
      demoModalService.show()
    },
  },
}
</script>

<style scoped>

</style>
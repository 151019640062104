const invoicingSlides = [
    {
        title: 'Factureren',
        content: 'Selecteer de clienten en onderdelen die gefactureerd moeten worden en met 1 druk op de knop wordt er gefactureerd.',
        image: require('@/assets/invoicing/Factureren.png'),
        left: 70,
        top: 32,
    },
    {
        title: 'Facturenoverzicht',
        content: 'Vanuit het facturenoverzicht kunnen facturen gemaild of opgeslagen worden. Natuurlijk met handige filtering.',
        image: require('@/assets/invoicing/Facturenoverzicht.png'),
        left: 60,
        top: 48,
    },
    {
        title: 'Betalingsoverzicht',
        content: 'Houd de betaalstatus van verstuurde facturen bij en verzend herinneringsfacturen.',
        image: require('@/assets/invoicing/Betalingsoverzicht.png'),
        left: 26,
        top: 12,
    },
]

export default invoicingSlides
const dossierSlides = [
    {
        title: 'Client',
        content: 'De persoonsgegevens en dossierinformatie van de cliënt worden veilig vastgelegd en bewaard.',
        image: require('@/assets/client/Client.png'),
        left: 55,
        top: 8,
    },
    {
        title: 'Client contacten',
        content: 'Dankzij de handige terugbellijst en aanmeldlijst zul je nooit meer een afspraak met de cliënt vergeten.',
        image: require('@/assets/client/Clientcontacten.png'),
        left: 60,
        top:32,
    },
    {
        title: 'Klachtregistratie',
        content: 'Dankzij de klachten- en incidentenmodule worden eventuele tekortkomingen en problemen snel en efficiënt opgelost.',
        image: require('@/assets/client/Klachtregistratie.png'),
        left: 70,
        top: 32,
    },
]

export default dossierSlides
const dossierSlides = [
    {
        title: 'Dossierscherm',
        content: 'Het dossierscherm is opgebouwd uit tegels en zeer overzichtelijk. Alle belangrijke informatie is zichtbaar in 1 scherm.',
        image: require('@/assets/dossier/Dossierscherm.png'),
        left: 55,
        top: 48,
    },
    {
        title: 'Sessies',
        content: 'De vraag "wat heb ik vorige keer gedaan?" is niet meer van toepassing. Iedere sessie kan inhoudelijk worden vastgelegd.',
        image: require('@/assets/dossier/Sessies.png'),
        left: 50,
        top: 40,
    },
    {
        title: 'Doelen',
        content: 'Met de evaluaties van de gestelde doelen kan de voortgang van de client duidelijk worden weergegeven.',
        image: require('@/assets/dossier/Doelen.png'),
        left: 5,
        top: 80,
    },
    {
        title: 'Taken',
        content: 'Met taken die aan het dossier zijn gekoppeld vergeet je geen stap meer in het behandelproces.',
        image: require('@/assets/dossier/Taken.png'),
        left: 15,
        top: 12,
    },
]

export default dossierSlides
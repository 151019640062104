<template>
  <div class="row">
    <div class="col">
      <a href="https://www.linkedin.com/company/nora-software/" target="_blank">
        <img src="@/assets/linkedin-40.png" alt="linkedin" class="img-fluid shake me-2"/>
      </a>
      <a href="https://twitter.com/NoraSoftware" target="_blank">
        <img src="@/assets/twitter-40.png" alt="twitter" class="img-fluid shake me-2"/>
      </a>
      <a href="https://www.instagram.com/norasoftware/" target="_blank">
        <img src="@/assets/insta-40.png" alt="instagram" class="img-fluid shake me-2"/>
      </a>
      <a href="https://www.youtube.com/channel/UCnY4E3KiBaVjoI_0cC0GlYw" target="_blank">
        <img src="@/assets/youtube-40.png" alt="youtube" class="img-fluid shake me-2"/>
      </a>
      <a href="https://www.facebook.com/profile.php?id=61554802163284" target="_blank">
        <img src="@/assets/facebook-40.png" alt="facebook" class="img-fluid shake me-2"/>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "socialTile"
}
</script>

<style scoped>
.shake:hover {
  animation: shake 0.5s;
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% { transform: rotate(0deg); }
  10% { transform: rotate(-15deg); }
  20% { transform: rotate(0deg); }
  30% { transform: rotate(15deg); }
  40% { transform: rotate(0deg); }
  50% { transform: rotate(-15deg); }
  60% { transform: rotate(0deg); }
  70% { transform: rotate(15deg); }
  80% { transform: rotate(0deg); }
  90% { transform: rotate(-15deg); }
  100% { transform: rotate(0deg); }
}

</style>
<template>
  <body>
    <div id="app">
      <div style="font-family: 'Nunito',system-ui;">
        <HeaderNewTile/>
        <intro/>
        <FooterTile/>
        <DemoModal/>
        <ScheduleModal/>
        <InvoicingModal :slides="invoicingModalService.state.slides"/>
      </div>


    </div>



  </body>
</template>

<script>
import FooterTile from "@/components/FooterTile";
import intro from '@/components/intro';
import DemoModal from '@/components/DemoModal';
import ScheduleModal from '@/components/ScheduleModal';
import HeaderNewTile from "@/components/HeaderNewTile";
import InvoicingModal from "@/components/InvoicingModal";
import invoicingModalService from "@/services/modals/invoicingModalService";

export default {
  name: 'App',
  components: {InvoicingModal, HeaderNewTile, FooterTile, ScheduleModal, DemoModal, intro},
  data() {
    return  {
      invoicingModalService,
    }
  },
  async mounted() {
    await this.$recaptchaLoaded()
    this.$recaptchaInstance.value.hideBadge()
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Nunito');

#app {
  font-family: 'Nunito', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
html {
  font-family: 'Nunito', sans-serif;
}
body {
  min-height: 100vh;
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  /*background: url('./assets/steiger2.jpg') center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;*/
}

.dialog-bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .5);
  z-index: 98;
  backdrop-filter: blur(5px);
}

.shapedividers_com-1329{
  overflow:hidden;
  position:relative;
}
.shapedividers_com-1329::before{
  content:'';
  position: absolute;
  bottom: -1px;
  left: -1px;
  right: -1px;
  top: -1px;
  z-index: 0;
  pointer-events: none;
  background-repeat: no-repeat;
  background-size: 128% 151px;
  background-position: 50% 100%;
  background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.28 2.17" preserveAspectRatio="none"><path d="M35.28 1.67c-3.07-.55-9.27.41-16.15 0-6.87-.4-13.74-.58-19.13.1v.4h35.28z" fill="%232ea0bd"/><path d="M35.28 1.16c-3.17-.8-7.3.4-10.04.56-2.76.17-9.25-1.47-12.68-1.3-3.42.16-4.64.84-7.04.86C3.12 1.31 0 .4 0 .4v1.77h35.28z" opacity=".5" fill="%232ea0bd"/><path d="M35.28.31c-2.57.84-7.68.3-11.8.43-4.1.12-6.85.61-9.57.28C11.18.69 8.3-.16 5.3.02 2.3.22.57.85 0 .87v1.2h35.28z" opacity=".5" fill="%232ea0bd"/></svg>');
  opacity: 0.3;
}

@media (min-width:768px){
  .shapedividers_com-1329::before{
    background-size: 128% 51px;
    background-position: 50% 100%;
  }
}

@media (min-width:1025px){
  .shapedividers_com-1329::before{
    bottom: -0.1vw;
    left: -0.1vw;
    right: -0.1vw;
    top: -0.1vw;
    background-size: 130% 240px;
    background-position: 50% 100%;
    transform: rotateY(180deg);
    background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.28 2.17" preserveAspectRatio="none"><path d="M35.28 1.67c-3.07-.55-9.27.41-16.15 0-6.87-.4-13.74-.58-19.13.1v.4h35.28z" fill="%2312a0d7"/><path d="M35.28 1.16c-3.17-.8-7.3.4-10.04.56-2.76.17-9.25-1.47-12.68-1.3-3.42.16-4.64.84-7.04.86C3.12 1.31 0 .4 0 .4v1.77h35.28z" opacity=".5" fill="%2312a0d7"/><path d="M35.28.31c-2.57.84-7.68.3-11.8.43-4.1.12-6.85.61-9.57.28C11.18.69 8.3-.16 5.3.02 2.3.22.57.85 0 .87v1.2h35.28z" opacity=".5" fill="%2312a0d7"/></svg>');
  }
}
@media (min-width:2100px){
  .shapedividers_com-1329::before{
    background-size: 130% calc(2vw + 188px);
  }
}
</style>

const dossierSlides = [
    {
        title: 'Dossier overzicht',
        content: 'Het dossieroverzicht biedt inzicht in de behandelingen met heldere grafieken, gedetailleerde data en handige filteropties, waardoor het beheren van informatie een fluitje van een cent wordt.',
        image: require('@/assets/overview/Dossieroverzicht.png'),
        left: 55,
        top: 8,
    },
    {
        title: 'Financieel overzicht',
        content: 'Het financiële overzicht biedt diepgaand inzicht in de omzet en winst met behulp van overzichtelijke grafieken en handige filtermogelijkheden.',
        image: require('@/assets/overview/Financieeloverzicht.png'),
        left: 10,
        top: 16,
    },
    {
        title: '',
        content: 'Ontdek talloze andere informatieve overzichten. Neem vandaag nog contact op met Nora Software voor een boeiende demonstratie.',
        image: require('@/assets/overview/Overzichten.png'),
        left: 15,
        top: 72,
    },
]

export default dossierSlides